export const numericalRules = function () {
  return [
    (v) => !!v || this.$_trans("validation.required"),
    (v) => !isNaN(v) || this.$_trans("validation.enter_just_digit"),
  ];
};

export const weightRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v >= 1.5 && v <= 280) || this.$_trans('validation.number_between_15_280'),
  ];
};
export const heightRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v >= 30 && v <= 250) || this.$_trans('validation.number_between_30_250'),
  ];
};
export const bustRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v >= 30 && v <= 180) || this.$_trans('validation.number_between_30_180'),
  ];
};
export const waistRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v >= 30 && v <= 180) || this.$_trans('validation.number_between_30_180'),
  ];
};
export const hipsRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v >= 30 && v <= 180) || this.$_trans('validation.number_between_30_180'),
  ];
};
export const shoeRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v >= 8 && v <= 40) || this.$_trans('validation.number_between_8_40'),
  ];
};
export const numberRules = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      (v > 0 && v < 1000) || this.$_trans('validation.number_between_0_1000'),
  ];
};

export const requiredRules = function () {
  return [(v) => !!v || this.$_trans('validation.required')];
};

export const modelTypesRules = function () {
  return [(v) => !!(v && v.length) || this.$_trans('validation.choice_one_item')];
};

export const password = function () {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) => (v && /\d/.test(v)) || this.$_trans('validation.min_one_digit'),
    (v) => (v && /[A-Z]{1}/.test(v)) || this.$_trans('validation.min_one_capital'),
  ];
}

export const jpLangRule = function() {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) =>
      /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(
        v
      ) || this.$_trans('validation.just_japanese'),
  ];
}

export const jpLangNoRequiredRule = function() {
  return [
    (v) =>
        (v?/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(
        v
      ) || this.$_trans('validation.just_japanese'):true)
  ];
}

export const isJpLang = function() {
  return (v) => (v?/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(
    v
  ) || this.$_trans('validation.just_japanese'):true)
}

export const isEnLang = function() {
  return  (v) => (v ? /^[a-zA-Z1-9]+$/.test(v) || this.$_trans('validation.just_english') : true)
}

export const emailValidation = function() {
  return [
    (v) => !!v || this.$_trans('validation.requiredEmail'),
    (v) => /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,4})+$/.test(v) || this.$_trans('validation.valid_email'),
  ];
}

export const enLangNameRule = function() {
  return [
    (v) => !!v || this.$_trans('validation.required'),
    (v) => !!v && v.length > 1 || this.$_trans('validation.name_length'),
    (v) => /^[a-zA-Z ]+$/.test(v) || this.$_trans('validation.valid_english'),
  ];
}

export const requiredRulesClient = function() {
  return [(v) => (!!v && !!Object.keys(v).length) || this.$_trans('validation.required')];
}

export const nameRules = function() {
  return [(v) => (!!v||
    (!!this.form.first_name&&!!this.form.last_name) ||
    (!!this.form.first_name_jpn&&!!this.form.last_name_jpn) ||
    this.$_trans('validation.name'))];
}

export const requiredPassword = function () {
  return [(v) => !!v || this.$_trans('validation.requiredPassword')];
};
