<template>
  <div class="request__details">
    <div :class="`text-body text-sm-h6 request__details--title-${defaultColor}`">
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: null,
    },
    color: {
      default: "black",
    },
  },
  computed: {
    defaultColor() {
      return this.color;
    },
  },
};
</script>
<style lang="scss" scoped>
.request__details div {
  font-size: 21px;
  font-family: "Barlow-EL" !important;
  text-transform: uppercase;
  letter-spacing: 10px !important;
  margin-bottom: 0;
}
.request__details div::after {
  display: block;
  height: 1px;
  background-color: var(--color-light-gray);
  content: "";
  width: 100%;
}
@media screen and (max-width: 600px) {
  .request__details div {
    font-size: 17px;
    letter-spacing: 2px !important;
  }
}
@import "@Styles/setup/variables";
@each $defaultColor, $value in $allColors {
  .request__details--title-#{ "" + $defaultColor} {
    color: $value !important;
  }
}
</style>
