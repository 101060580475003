<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
              :login="login"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <v-container>
      <main class="mb-5 loading__container">
        <loading v-if="loadingData" class="loading-data" />
        <list class="wa__large" :class="{ 'loading-form': loadingData }">
          <v-form @submit.prevent="updateByUser" ref="profileForm">
            <div class="info__box">
              <v-row>
                <!-- <v-col cols="3" class="pr-10 d-none d-lg-block">
                <div class="w-100 position-relative mt-2 profile__image">
                  <img
                    :src="avatar"
                    alt="super model image"
                    class="h-100 w-100 rounded"
                    style="object-fit: cover"
                  />
                  <div class="profile__image--label text-uppercase position-absolute">
                    our super model:
                  </div>
                  <div class="profile__image--text text-white position-absolute">
                    Sofia Johnson
                  </div>
                </div>
              </v-col> -->
                <!-- <v-col cols="12" lg="9" class="pl-0 pt-5 pr-0 pr-md-3"> -->
                <v-col cols="12" class="pl-0 pt-5 pr-0 pr-md-3">
                  <SectionTitle title="my info" />
                  <div class="mt-5 mt-sm-2">
                    <ul class="d-flex pl-0 mb-0 list-unstyled">
                      <li class="pr-0 pr-sm-2">
                        <v-text-field
                          class="pt-sm-3 pt-md-4"
                          :label="$_t('firstName')"
                          v-model="form.first_name"
                          color="SonicSilver"
                          dense
                          outlined
                          :rules="enLangNameRule"
                        ></v-text-field>
                      </li>
                      <li class="pl-0 pl-sm-2 pr-sm-0 px-md-2">
                        <v-text-field
                          class="pt-0 pt-sm-3 pt-md-4"
                          :label="$_t('lastName')"
                          v-model="form.last_name"
                          color="SonicSilver"
                          dense
                          outlined
                          :rules="enLangNameRule"
                        ></v-text-field>
                      </li>
                      <li class="pr-0 pr-sm-2 pl-sm-0 px-md-2">
                        <v-text-field
                          class="pt-0 pt-md-4"
                          :label="$_t('japaneseLastName')"
                          v-model="form.last_name_jpn"
                          color="SonicSilver"
                          dense
                          outlined
                          :rules="[...nameRules,isJpLang]"
                        ></v-text-field>
                      </li>
                      <li class="pl-0 pl-sm-2 px-lg-2">
                        <v-text-field
                          class="pt-0 pt-md-4"
                          :label="$_t('japaneseFirstName')"
                          v-model="form.first_name_jpn"
                          color="SonicSilver"
                          dense
                          outlined
                          :rules="[...nameRules,isJpLang]"
                        ></v-text-field>
                      </li>
                      <li class="pr-0 pr-sm-2 px-lg-2">
                        <v-text-field
                          class="pt-0 pt-md-4"
                          :label="$_t('cellPhone')"
                          v-model="form.cell_phone"
                          color="SonicSilver"
                          type="number"
                          dense
                          outlined
                        ></v-text-field>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul class="d-flex pl-0 list-unstyled mb-0">
                      <li class="pr-0 pr-sm-2">
                        <date-input
                          :label="$_t('birthday')"
                          v-model="form.birthday"
                          color="SonicSilver"
                          dense
                          outlined
                          :max="today"
                        ></date-input>
                      </li>
                      <li class="pl-0 pl-sm-2 px-md-2">
                        <v-autocomplete
                          class="pt-0 mt-0"
                          :label="$_t('nationality')"
                          item-value="id"
                          item-text="name"
                          color="SonicSilver"
                          :items="getEthnics"
                          v-model="form.ethnic_id"
                          dense
                          outlined
                        ></v-autocomplete>
                      </li>
                      <li class="pr-0 pr-sm-2 pl-sm-0 px-md-2">
                        <v-autocomplete
                          class="pt-0 mt-md-0"
                          :label="$_t('gender')"
                          :items="getGenders"
                          color="SonicSilver"
                          v-model="form.gender_id"
                          item-value="id"
                          item-text="name"
                          dense
                          outlined
                        ></v-autocomplete>
                      </li>
                      <li class="px-0 pl-sm-2 pr-md-2">
                        <v-autocomplete
                          :label="$_t('country')"
                          color="SonicSilver"
                          class="pt-0 mt-md-0"
                          :items="getCountries"
                          item-value="id"
                          item-text="name"
                          v-model="form.country_id"
                          dense
                          outlined
                        ></v-autocomplete>
                      </li>
                      <li class="pl-0 pr-0 pr-sm-2 pl-md-2 px-lg-2">
                        <!-- <v-autocomplete
                        :label="$_t('city')"
                        color="SonicSilver"
                        class="pt-0 mt-md-0"
                        :items="getCities"
                        v-model="form.city_id"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                      ></v-autocomplete> -->
                        <v-text-field
                          class="pt-0 mt-0"
                          :label="$_t('city')"
                          color="SonicSilver"
                          v-model="form.city"
                          dense
                          outlined
                        ></v-text-field>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul class="d-flex pl-0 list-unstyled">
                      <li class="">
                        <v-text-field
                          :label="$_t('email')"
                          class="pr-0 pr-sm-2 pt-0 pt-sm-1 pt-md-3"
                          disabled
                          color="SonicSilver"
                          v-model="form.email"
                          dense
                          outlined
                        ></v-text-field>
                      </li>
                      <li class="px-0 pr-sm-2 pl-sm-2">
                        <v-text-field
                          class="pt-sm-1 pt-md-3 no-error-msg"
                          :label="$_t('corporate')"
                          color="SonicSilver"
                          v-model="form.corporate"
                          dense
                          outlined
                        ></v-text-field>
                      </li>
                    </ul>
                  </div>
                  <div class="mt-7 mt-md-9">
                    <ul class="d-flex pl-0 list-unstyled model_types">
                      <li>
                        <v-autocomplete
                          class="pt-0"
                          color="SonicSilver"
                          :items="getModelTypes"
                          item-value="id"
                          item-text="name"
                          v-model="form.model_types"
                          attach
                          chips
                          :label="$_t('modelTypes')"
                          multiple
                          return-object
                          dense
                          outlined
                          item-color="black"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              class="mt-1 model_types--chip"
                              label
                              small
                              :input-value="data.selected"
                            >
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </li>
                    </ul>
                  </div>
                  <v-row
                    class="w-80 mt-0 mt-md-3"
                    v-for="index in form.languagesWithLevel.length"
                    :key="index"
                  >
                    <v-col cols="12" class="pt-1">
                      <v-card flat class="add__language">
                        <v-row>
                          <v-col cols="12" md="5" class="py-0 pr-6">
                            <v-autocomplete
                              color="SonicSilver"
                              class="mt-4"
                              :label="$_t('language')"
                              :items="getLanguages"
                              v-model="form.languagesWithLevel[index - 1].id"
                              item-value="id"
                              item-text="name"
                              dense
                              outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4" class="py-0 pr-6">
                            <v-autocomplete
                              color="SonicSilver"
                              class="mt-4"
                              :label="$_t('level')"
                              :items="getLanguageLevels"
                              item-value="id"
                              item-text="name"
                              v-model="
                                form.languagesWithLevel[index - 1].level_id
                              "
                              dense
                              outlined
                            ></v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                            class="
                              pt-0 pt-md-3
                              mb-2
                              text-right
                              pr-6 pr-md-3
                              align-center
                              d-flex
                              justify-end
                            "
                          >
                            <block-button
                              :text="$_t('remove')"
                              height="20"
                              class="px-4"
                              color="red"
                              bg-color="bgLightPink"
                              @click.native="removeLanguageRow(index)"
                            ></block-button>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="w-80 mt-0">
                    <v-col cols="12 text-right">
                      <block-button
                        :text="$_t('add')"
                        height="20"
                        class="mr-4 px-7"
                        color="cyan"
                        bg-color="bgCyan"
                        @click.native="addLanguageRow"
                      ></block-button>
                    </v-col>
                  </v-row>
                  <v-row class="w-80 mt-0">
                    <v-col cols="12" class="text-justify">
                      <v-textarea
                        class="pt-0 no-error-msg"
                        color="SonicSilver"
                        rows="3"
                        :label="$_t('bio')"
                        v-model="form.bio"
                        dense
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="w-80 mt-0">
                    <v-col cols="12" class="text-justify">
                      <v-textarea
                        class="pt-0 no-error-msg"
                        color="SonicSilver"
                        rows="3"
                        :label="$_t('bio_jpn')"
                        v-model="form.bio_jpn"
                        dense
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row class="mt-6">
                    <v-col cols="12">
                      <block-button
                        height="30"
                        class="
                          btn__update__profile
                          site__button
                          width-auto
                          float-right
                          ml-5
                        "
                        :text="$_t('updateProfile')"
                        :loading="requestLoading"
                        type="submit"
                      ></block-button>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </list>
      </main>
    </v-container>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Avatar from "../assets/47cddd0849f5845fbbf9026e22736b9a.jpg";
import SectionTitle from "../components/Global/Section/SectionTitle.vue";
import UserRepository from "../abstraction/repository/userRepository";
const userRepository = new UserRepository();
import { UserStorage } from "../utils/storage";
import {nameRules,enLangNameRule,isJpLang} from "@/mixins/validate"
export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      form: {
        languagesWithLevel: [],
      },
      requestLoading: false,
      login: true,
      avatar: Avatar,
      loadingData: false,
      today: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("ethnic", ["getEthnics"]),
    ...mapGetters("modelType", ["getModelTypes"]),
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    ...mapGetters("country", ["getCountries"]),
    enLangNameRule,
    isJpLang,
    nameRules,
  },
  methods: {
    ...mapActions("modelType", ["loadModelTypes"]),
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("ethnic", ["loadEthnics"]),
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    ...mapActions("country", ["loadCountries"]),
    addLanguageRow() {
      this.form.languagesWithLevel.push({
        level_id: null,
        id: null,
      });
    },
    removeLanguageRow(index) {
      this.form.languagesWithLevel.splice(index - 1, 1);
      if (this.form.languagesWithLevel.length === 0) {
        this.form.languagesWithLevel.push({
          level_id: null,
          id: null,
        });
      }
    },
    async updateByUser() {
      try {
        if (!this.$refs.profileForm.validate()) {
          return;
        }
        this.requestLoading = true;
        const response = await userRepository.updateProfile(this.form);
        if (response) {
          this.$router.push({
            name: "dashboard",
            params: {
              locale: this.$_getlocaleParam(),
            },
          });
        }
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
    async loadMyInfo() {
      this.loadingData = true;
      this.form = await userRepository.myInfo();
      if (this.form) {
        UserStorage.save(this.form);
      }
      this.loadingData = false;
    },
  },
  created() {
    this.loadModelTypes();
    this.loadGenders();
    this.loadEthnics();
    this.loadMyInfo();
    this.loadLanguages();
    this.loadLanguageLevels();
    this.loadCountries();
  },
};
</script>

<style scoped>
/* container */
.container {
  max-width: 1440px;
}

.loading__container {
  position: relative;
}

.loading-data {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.loading-form {
  filter: blur(5px);
}

.info__box {
  padding: 30px 20px;
}

.profile__image {
  height: 490px;
}

.profile__image--text {
  bottom: 50px;
  left: 70px;
  font-size: 17px;
  letter-spacing: 3px;
  font-family: "Barlow-EL";
  color: var(--color-white);
}

.profile__image--label {
  font-family: "Montserrat-regular";
  font-size: 10px;
  color: var(--color-smoky);
  bottom: 70px;
  left: 70px;
}

ul li {
  width: 19%;
}

.model_types li {
  width: 56%;
}

.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}

.description {
  font-size: 13px;
  font-family: "Montserrat-regular" !important;
}

.btn__update__profile {
  font-family: "Barlow-EL";
  font-size: 13px;
  padding: 0 90px !important;
  letter-spacing: 4px !important;
}
.model_types--chip {
  font-family: "montserrat-regular";
}
.add__language {
  border: 1px solid var(--color-light-gray);
  padding: 10px 15px 0 15px;
  border-radius: 5px;
}

.address {
  width: 75.3%;
}

@media screen and (max-width: 1264px) {
  .info__box {
    padding: 0 20px;
  }
}

@media screen and (max-width: 960px) {
  ul {
    flex-wrap: wrap;
  }

  ul li {
    width: 50%;
  }

  .model_types li {
    width: 100%;
  }

  .address {
    width: 100%;
  }

  .add__language {
    height: 100% !important;
    padding-right: 0 !important;
  }
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .btn__update__profile {
    padding-right: 12px !important;
    padding-left: 12px !important;
    font-size: 0.625rem !important;
    width: 100% !important;
  }

  ul li {
    width: 100%;
  }
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
</style>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
